import React, {useState} from 'react';
import './style.css'; // Tell Webpack that these styles are used
import './navbar.css'; // Tell Webpack that these styles are used

import logoLarge from './images/logo-large.png';
import logoSmall from './images/logo-small.png';
import tagLine from './images/tagline.png';
import calculator from './icons/calculator.png';
import copyright from './icons/copyright.png';
import dollar from './icons/dollar.png';
import factory from './icons/factory.png';
import gavel from './icons/gavel.png';
import pen from './icons/pen.png';
import store from './icons/store.png';
import mail from './icons/mail.png';
import phone from './icons/phone.png';
import hamburger from './icons/hamburger.png';
import markCaperton from './images/mark-caperton.jpg';
import dirkMoore from './images/dirk-moore.jpg';
import toddSmith from './images/todd-smith.jpg';
import austinOffice from './images/austin-office.jpg';
import caldwellOffice from './images/caldwell-office.jpg';
import lakewayOffice from './images/lakeway-office.jpg';
import houstonOffices from './images/houston-offices.jpg';

const Homepage = () => {

    const [navStyle, setNavStyle] = useState("topnav");

    const processClick = () => {

        if (navStyle === "topnav responsive") {
            console.log(navStyle)
            setNavStyle("topnav")
        } else {
            console.log(navStyle)
            setNavStyle("topnav responsive")
        }
    }

    return (

        <div className="content">

{/* NAVBAR */}

            <div className={navStyle} id="myTopnav">
                <a href="#home" className="active" id="logo"><img src={logoSmall} alt="Caperton & Moore PLLC"/></a>
                <a href="#practice-areas" onClick={processClick}>Practice areas</a>
                <a href="#legal-team" onClick={processClick}>Legal team</a>
                <a href="#client-relationships" onClick={processClick}>Client relationships</a>
                <a href="#contact" onClick={processClick}>Contact</a>
                <a href="#" className="icon" onClick={processClick}>
                    <img src={hamburger} alt="menu" id="hamburger"/>
                </a>
            </div>

{/* HOME */}

            <section id="home">
                <div className="section-header" id="home">
                    <div className="firm-name">
                        <img src={logoLarge} alt="Caperton & Moore PLLC"/>
                    </div>
                    <div className="tagline">
                        <img src={tagLine} alt="Experience you can trust"/>
                    </div>
                </div>
                <div className="section-details" id="home">
                    {/* <h1>Experience you can trust</h1> */}
                    <p>With primary offices located in Austin and Caldwell, Caperton & Moore has a broad business practice encompassing most aspects of real estate, oil and gas, eminent domain, corporate, tax, partnership, estate planning, probate, banking, commercial lending, intellectual property, and related business/commercial and probate litigation.</p>
                    <p>Our client base includes a variety of corporations, partnerships, limited liability companies, trusts, real estate developers and owners, contractors, brokerage firms, mortgage investors, entrepreneurs and professionals, including engineers, physicians, accountants and other attorneys.</p>
                </div> 
            </section>

{/* PRACTIVE AREAS */}

            <section id="practice-areas">
                <div className="section-header" id="practice-areas">
                    <h1>Practice areas</h1>
                </div>
                <div className="section-details" id="practice-areas">
                    <div className="practice-area">
                        <div className="icon">
                            <img src={pen} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Estate Planning, Trusts, Wills, and Estate Administration</h1>

                            <p>We provide individually-tailored, family-focused counselling as a part of the firm’s estate planning practice. Services include the preparation of wills, powers-of-attorney, and trusts, from the simplest to the most complex of needs. Our goal is aimed at the preservation and management of assets on an intergenerational basis, providing a smooth and tax-efficient transition of business and personal assets, all while protecting the relationships and interests of individual family members consistent with the client’s non-tax goals.</p>
                            <p>Our Estate Planning services additionally include (but are not limited to) the following:</p>
                            <ul>
                                <li>generation-skipping transfer tax (GST) planning;</li>
                                <li>gift trusts, including life insurance trusts (ILITs), annuity trusts (GRATs);</li>
                                <li>gift planning with sales to intentionally defective grantor trusts (IDGTs);</li>
                                <li>family limited partnerships (FLPs) and/or family limited liability companies (FLLCs);</li>
                                <li>qualified personal residence trusts (QPRTs);</li>
                                <li>business succession planning, including “buy-sell” arrangements;</li>
                                <li>cohabitation agreements and marital property agreements (both antenuptial and postnuptial); and</li>
                                <li>charitable foundations and charitable trusts (CRTs, CLTs).</li>
                            </ul>
                            <p>We assist each client in identifying personal, financial, and charitable goals, offering strategies that will help the client to achieve his or her individual objectives.</p>
                            <p>Our estate planning attorneys also have extensive experience representing fiduciaries (both individual and corporate) in the probate of wills, related estate administration, and the administration of trusts. Our expertise in this area of the law brings the experience and knowledge necessary to allow executors and trustees to meet their fiduciary duties. Our representation includes all matters related to the administration of estates and trusts, from matters before the IRS to the counselling, preparation, and execution of asset distributions and the related income and transfer tax implications for the related estates and trusts (and their respective beneficiaries).</p>
                            <p>Working closely with our estate planning and probate attorneys, our litigation team handles all manner of probate, estate, and trust disputes, whether handling the defense of our fiduciary clients, or representing beneficiaries or other interested parties with complaints related to will/trust contests or the administration of an estate or trust by a fiduciary not represented by our firm.</p>
                        </div>
                    </div>

                    <div className="practice-area">
                        <div className="icon">
                            <img src={factory} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Planning For Business Organizations</h1>

                            <p>Our business planning practice involves assisting our clients in all aspects of their business from formation through operation, disposition, succession, and acquisition of targets. We assist entrepreneurs starting new businesses with entity selection and formation, tax planning, and governance matters.</p>

                            <p>We serve as outside general counsel for entities already in existence with on-going business operation issues. This often involves tax planning, general contract matters, buy/sell agreements, employment contracts, noncompetition and nonsolicitation covenants, compensation agreements including option agreements, phantom stock arrangements, bonus plans, and severance agreements.</p>

                            <p>We represent both sellers and purchasers of business entities and assets, and entities reorganizing, merging or converting. We work closely with our clients’ other advisers, such as financial planners, accountants, insurance agents, and brokers, to coordinate their business plans.</p>
                            
                            <p>We also represent a wide range of businesses. Our clients include, among others, service, tool, and equipment companies that are ancillary to the oil, gas, and petrochemical industries; real estate developers and brokers; a diverse group of physicians and other healthcare providers in connection with the business aspects of their practices; banks and credit unions; lenders; and retail and restaurant businesses.</p>
                        </div>
                    </div>
                    
                    <div className="practice-area">
                        <div className="icon">
                            <img src={dollar} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Banking and Finance</h1>

                            <p>The firm and its attorneys have extensive experience representing banks and other lenders in various aspects of commercial lending, including the following:</p>

                            <ul>
                                <li>construction, mini-perm and permanent loans covering various real estate projects such as apartments, condominiums, retail shopping centers, medical office building and surgical centers and development of acreage into residential and commercial lots;</li>
                                <li>term loans for the purchase and sale of real estate;</li>
                                <li>term loans secured by ship or fleet mortgages;</li>
                                <li>unsecured credit facilities;</li>
                                <li>asset-based lending facilities; and</li>
                                <li>syndicated credit facilities.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="practice-area">
                        <div className="icon">
                            <img src={calculator} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Tax</h1>

                            <p>We counsel start-up and existing businesses with regard to tax planning for federal income tax and Texas margin tax. We also advise individuals regarding federal income, gift, and estate tax matters and offer strategies to achieve favorable treatment.</p>
                        </div>
                    </div>

                    <div className="practice-area">
                        <div className="icon">
                            <img src={store} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Commercial Real Estate</h1>
                            <p>Our real estate attorneys represent clients in:</p>
                            <ul>
                                <li>buying, selling and developing retail shopping centers, office buildings, raw land, ranch properties, apartment complexes, condominiums, and industrial properties;</li>
                                <li>leasing matters from either the landlord or tenant’s perspective;</li>
                                <li>negotiating architectural and construction contracts; and</li>
                                <li>other matters related to the acquisition, development, and ownership of real estate.</li>
                            </ul>
                            <p>We review and address title and survey matters, and we negotiate and prepare purchase and sale agreements and related closing documents, loan documentation, lease agreements, and other documentation necessary for commercial real estate transactions. Our real estate attorneys work closely with our other attorneys who engage in tax and business planning in determining and creating the appropriate ownership structure for such ventures. In addition, we frequently represent investors in all of the foregoing types of transactions.</p>
                        </div>
                    </div>
                
                    <div className="practice-area">
                        <div className="icon">
                            <img src={gavel} alt=""/>
                        </div>
                        <div className="description">
                            <h1>Commercial and Probate/Trust Litigation</h1>
                            <p>Our litigation attorneys handle the disputes arising in our clients’ lives, using their courtroom skills and professional demeanor to represent our clients with integrity before mediators, judges, juries, and arbiters throughout Texas, and in the Federal Courts. Our trial lawyers’ skills and experience include representation related to:</p>
                            <ul>
                                <li>commercial litigation;</li>
                                <li>construction litigation;</li>
                                <li>real estate litigation;</li>
                                <li>contested and uncontested estate, probate, and trust matters; and</li>
                                <li>insurance coverage issues.</li>
                            </ul>

                            <p>Among our goals is to provide timely and cost-effective representation in light of the facts and law involved in a particular case, often consisting of a team which includes our attorneys, the client, and any matter-related experts.  Whether disputes between individuals or complex commercial matters involving multiple related lawsuits and parties, our trial attorneys employ thorough preparation, counselling, and analysis to assist clients in limiting their exposure and mitigating their risks related to all types of litigation.  Our attorneys relentlessly pursue optimal results for our clients, looking for solutions to disputes that take into consideration our client’s budget and desire to limit litigation costs and expenses; however, should a trial become necessary, our attorneys will be ready to try the case in any venue, including appeals in state and federal courts.</p>
                            
                        </div>
                    </div>

                    <div className="practice-area" id="intellectual-property">
                        <div className="icon">
                            <img src={copyright} alt=""/>
                        </div>
                        <div className="description">
                            <h1>
                                
                                Intellectual Property
                            </h1>

                            <h2>Patent Litigation</h2>
            
                                <p>We understand the issues involved in high-stakes patent litigation.  We have experience litigating in the nation’s busiest venues for patent disputes, including the Eastern District of Texas, the Northern District of California, and Delaware. We have handled cases involving a wide range of technologies, including wireless location services, semiconductor manufacturing, network communications, data compression, flash memory, and medical devices.</p>
            
                            <h2>Trademark Prosecution and Litigation</h2>
            
                                <p>We work with clients to protect and enhance their brands and corporate identity through strategic creation, planning and management of trademark portfolios. In addition, we help clients maintain and defending trademark, trade name and trade dress infringement actions in federal and state courts.</p>
                        </div>
                    </div>
                </div>

            </section>

{/* LEGAL TEAM */}

            <section id="legal-team">
                <div className="section-header" id="legal-team">
                    <h1>Legal team</h1>
                </div>
                <div className="section-details" id="legal-team">

                    <div className="lawyer-info" id="mark-caperton">

                        <div className="lawyer-summary">
                            <div className="lawyer-photo">
                                <img src={markCaperton} alt="Mark Caperton"/>
                            </div>
                            <p className="attorney-name">Mark Caperton</p>
                            <p className="attorney-title">Partner</p>
                            {/* <p className="attorney-email"><a href="mailto:mcaperton@capertonmoore.com">mcaperton@capertonmoore.com</a></p> */}
                        </div>

                        <div className="lawyer-bio">
                            <p>The career of Mark Caperton has been multifaceted encompassing law, business and politics.</p>
                            <p>Mark Caperton is a native of Caldwell, Texas, graduating in 1965 from Caldwell High School. Caperton received his B.B.A. in Finance from Texas A&M University in the Class of 1969 and J.D. from the University of Texas Law School in the Class of 1972.</p>
                            <p>Caperton has been practicing law for over 45 years and has served not only as a lawyer, but was Burleson County Judge from 1975-1983. He served as Vice Chairman of the Brazos Valley Development Council. Mark Caperton previously represented Austin Yellow Cab and Fort Worth Yellow Cab as their attorney. Martindale-Hubbell rates Mark Caperton as an AV Preeminent Lawyer.</p>
                            <p>Experienced in many legal areas, Caperton is also a businessman. He served as Vice Chairman and a major stockholder in First State Bank in Caldwell, Texas (now BB&T), First State Bank of Chilton, Texas (now First State Bank of Central Texas) which Caperton was instrumental in moving to Temple, Texas. He co-founded and served as Chairman of the Watauga State Bank (now Wells Fargo) in Fort Worth, Texas. Caperton was the co-founder and major stockholder in Burleson County Implement Company (a John Deere dealership) and Caperton & Towslee Title Company, as well as Caperton Insurance Company.</p>
                            <p>While attending University of Texas Law School, Mark Caperton founded Caperton Land Company, a full service real estate firm, encompassing real estate brokerage, real estate acquisitions and real estate development in Central Texas. Caperton has been active as a co-developer in the Westlake Hills area of Travis County including the Lost Creek subdivision. Another major project in which Caperton remains active is Towers Royalty, LLC., which was founded by Caperton.</p>
                            <p>Mark Caperton is active in the Oil and Gas business and founded Towers Royalty, LLC., which is involved in the acquisition and sale of oil and gas leases, mineral interests, and the development of oil and gas projects.</p>
                        </div>
                    </div>

                    <div className="lawyer-info" id="dirk-moore">

                        <div className="lawyer-summary">
                            <div className="lawyer-photo">
                                <img src={dirkMoore} alt="Dirk Moore"/>
                            </div>
                            <p className="attorney-name">Dirk R. Moore</p>
                            <p className="attorney-title">Partner</p>
                            {/* <p className="attorney-email"><a href="mailto:dmoore@capertonmoore.com">dmoore@capertonmoore.com</a></p> */}
                        </div>

                        <div className="lawyer-bio">
                            <p>Board Certified in Estate Planning and Probate Law by the Texas Board of Legal Specialization, Dirk’s practice focuses on integrating his legal, financial and business experience to support his clients.</p>
                            <p>Dirk assists clients in planning for and managing intergenerational transfers of wealth while also minimizing tax consequences. He has extensive experience in trust matters, pre-and post-marital agreements, tax planning for closely held businesses, and charitable law matters. Dirk has counseled fiduciaries in the administration of trusts and estates, including related probate and trust litigation.</p>
                            <p>Dirk also works with the firm’s real estate and corporate clients with regard to contract negotiations, business formations, acquisitions, financing arrangements and other needs. Dirk has represented borrowers, banks, and other lenders in a variety of commercial lending transactions, including construction, revolving, and permanent financing, secured by retail shopping centers, airplane hangars, commercial assets, apartments, residential and commercial condominiums, medical facilities and acreage being developed for residential and commercial lots. Dirk’s experience includes transactions from under $1 million to over $100 million. In addition to handling the lending aspect of his real estate client’s transactions, Dirk has extensive experience representing developers and owners of commercial and mixed use real estate projects such as apartments, commercial and residential condominiums, retail shopping centers, medical facilities and developers of acreage into master planned communities with residential and commercial lots.</p>
                            <p>Dirk also previously served as an assistant vice president and trust officer with a private trust company in Houston where he managed comprehensive financial planning and investment strategies, including advising clients on the negotiation of employment agreements, retirement analysis, and review of overall asset allocation needs.</p>

                            <h3>Professional Licenses, Certifications & Certified Legal Specialties</h3>
                            <ul>
                                <li>Board Certified, Estate Planning and Probate Law by the Texas Board of Legal Specialization</li>
                                <li>Attorney admitted to State Bar of Texas – 1998</li>
                            </ul>
                        
                            <h3>Professional Associations & Memberships</h3>
                            <ul>
                                <li>State Bar of Texas, Real Estate, Probate, and Trust Law Section</li>
                                <li>State Bar of Texas, Taxation Section</li>
                                <li>Houston Bar Association, Probate, Trusts and Estate Section</li>
                                <li>Austin Bar Association, Real Estate Section</li>
                                <li>Austin Bar Association, Estate Planning and Probate Section</li>
                                <li>Houston Estate and Financial Forum</li>
                            </ul>
            
                            <h3>Education</h3>
                            <ul>
                                <li>The University of Texas School of Law: Doctor of Jurisprudence, 1998</li>
                                <li>University of Houston: Bachelor of Arts in English, 1995</li>
                                <li>Honors College Graduate</li>
                            </ul>
                        </div>
                    </div>
                        
                    <div className="lawyer-info" id="todd-smith">

                        <div className="lawyer-summary">
                            <div className="lawyer-photo">
                                <img src={toddSmith} alt="Todd Smith"/>
                            </div>
                            <p className="attorney-name">Todd Smith</p>
                            <p className="attorney-title">Of Counsel</p>
                            {/* <p className="attorney-email"><a href="mailto:tsmith@capertonmoore.com">tsmith@capertonmoore.com</a></p> */}
                            
                        </div>

                        <div className="lawyer-bio">
                            <p>Todd's practice focuses on intellectual property, entertainment, and real estate law.  He has practiced at large, international firms, including Baker Botts and King & Spalding.  He has litigated intellectual property matters in the federal courts for many years and has represented major U.S. companies, including Google, Nokia, and Microsoft.  He is a registered patent attorney with extensive experience handling matters in the United States Patent and Trademark Office. </p>
                            <h3>Professional Licenses, Certifications & Certified Legal Specialties</h3>
                            <ul>
                                <li>Licensed to practice in Texas, New York, and Louisiana</li>
                                <li>Registered Patent Attorney, United States Patent and Trademark Office</li>
                            </ul>
            
                            <h3>Education</h3>
                            <ul>
                                <li>Benjamin N. Cardozo School of Law, New York, NY, Juris Doctorate, 2006</li>
                                <li>Rice University, Houston, TX, B.S., Electrical Engineering, 1995</li>
                            </ul>
                        </div>
                    </div>
                    </div>
            </section>

{/* CLIENT RELATIONSHIPS */}

            <section id="client-relationships">
                <div className="section-header" id="client-relationships">
                    <h1>Client relationships</h1>
                </div>

                <div className="section-details" id="client-relationships">

                    <h1>How Do I Become A Client?</h1>
                    <div className="client-header">
                        <div className="circle-number">1</div>
                        <h2>Contact Us</h2>
                    </div>
                    <p>The first step is for you to contact us by phone or e-mail. When you contact us, our Welcome Team will obtain basic information needed to ensure that your inquiry is directed to the appropriate attorney or staff member. You should be prepared to give us basic details, such as your name and contact information, as well as a brief description of your matter, such as “I need a will,” or “I have been sued by an employee,” or “I need help with trouble I’m having with a neighbor/insurance company/business partner.”</p>
                    
                    <div className="client-header">
                        <div className="circle-number">2</div>
                        <h2>Initial Consultation</h2>
                    </div>
                    <p>Once you have contacted us, a member of our Legal Team will contact you to have a brief discussion to determine whether our Legal Team is able to take on your legal matter. This brief discussion carries no obligation or charge to you, but allows us to properly assess whether we are the best option for you, particularly as it relates to the type of legal matter for which you are seeking representation. We will not be giving any legal advice at this point and will not do so unless and until you become a client (as outlined in the following steps).</p>

                    <div className="client-header">
                        <div className="circle-number">3</div>
                        <h2>Additional Follow-Up</h2>
                    </div>
                    <p>If it is decided that we need a more substantial meeting following the initial consultation in order to determine whether employing our legal services would benefit you, we will contact you to set up that meeting. This is much more likely in situations where your matter involves pending or anticipated litigation, and such further consultation will likely involve your bringing related documentation for us to review. We will let you know at this point whether there will be a charge for this more detailed legal consultation as a part of scheduling it.  If we can assess appropriate scope and costs for your matter after our brief initial consultation, we will usually be able to skip directly to the next step.</p>

                    <div className="client-header">
                        <div className="circle-number">4</div>
                        <h2>Service Proposal</h2>
                    </div>
                    <p>The next step is for us to prepare and send to you a formal written proposal of the legal services we will provide to you – referred to as our “Engagement Letter.” The Engagement Letter will outline the scope of our services and will include information on our fees, billing procedures, and general terms of our employment by you. The Engagement Letter will include any appropriate estimates on fees together with the amount we require to be deposited by you, often referred to as a “retainer.”  The retainer acts as security for future legal fees and expenses incurred and is due when you return the signed copy of the Engagement Letter.  We will not be able to begin work on your matter until we receive the fully executed Engagement Letter and the requested retainer.</p>
                    
                    <div className="client-header">
                        <div className="circle-number">5</div>
                        <h2>Hiring Us</h2>
                    </div>
                    <p>After you receive the Engagement Letter and you have determined that it is acceptable and you wish to retain our firm for legal services, you will need to sign and return the Engagement Letter to us, along with any requested retainer. Upon our receipt of both an Engagement Letter signed by you and deposit of the requested retainer, you will be our client and our work on your behalf will commence. We will schedule further consultations or make additional information requests, if any are needed, and begin work on your matter right away.</p>
                    
                    <div className="client-header">
                        <div className="circle-number">6</div>
                        <h2>Representation</h2>
                    </div>
                    <p>The duration of our engagement will vary depending upon the nature of your matter. For instance, the preparation of estate planning documents such as Wills, Trusts, and powers of attorney is, absent urgent circumstances, typically a 2-4 week process from our receipt of your signed Engagement Letter and retainer through the signing ceremony that concludes the scope of such an engagement. For litigation, the timeline is usually impossible to predict at the onset, but we will keep you informed of developments along the way and provide updates on any unforeseen expenses or costs in order for you to make fully informed decisions that take into consideration your financial risks and potential rewards. Our goal is to ensure that every action we take during our representation of you is undertaken with the utmost integrity and diligence in service of obtaining the best outcome possible for you in light of all related circumstances.</p>

                    <h1>Exceeding Expectations</h1>
                    <p>It is always our goal that our service exceeds our clients’ expectations.  As such, we are confident that you will not have any complaints. But if you do, you can rest assured that you have redress through the State Bar of Texas, which investigates and prosecutes professional misconduct committed by Texas attorneys.  Although not every complaint against or dispute with a lawyer involves professional misconduct, the State Bar’s Office of Chief Disciplinary Counsel will provide you with information about how to file a complaint, as referenced in our Engagement Letter.</p>

                    <h1>Our Options On Fee Arrangements</h1>
                    <p>As one of our firm’s core principles, we are committed to diligent and exceptional client care. In our view, this includes an open and transparent billing process which includes timely and open discussions where developments indicate that our prior fee estimates were too conservative.  While our normal method of billing is based upon a charge for the time we spend on your matter multiplied by that Legal Team member’s hourly rate, we understand that circumstances sometimes arise when for budgetary or other reasons a client desires to proceed upon, or it is more appropriate to charge for services upon, a flat-fee basis.  Sometimes we are able to accept an arrangement for which certain limited, well-defined tasks are performed on a flat-fee basis where other aspects of our representation continue to be performed on the normal hourly-rate basis.  In all instances, we encourage our clients to have open and frank discussions about legal fees so that an acceptable arrangement for both parties is achieved.</p>

                </div>
            </section>

{/* CONTACT */}

            <section id="contact">
                <div className="section-header" id="contact">
                    <h1>Contact</h1>
                </div>

                <h2>Austin</h2>
                <div className="contact-all">
                    
                    <div className="contact-text">
                        
                        <div className="city">
                            <img src={austinOffice} alt="Austin office"/>
                        </div>
                        <div className="contact-info">
                            <img src={phone} alt=""/>
                            <a href="tel:512-329-2076">(512) 329-2076</a>
                        </div>
                        <div className="contact-info">
                            <img src={mail} alt=""/>
                            <a href="mailto:info@capertonmoore.com">info@capertonmoore.com</a>
                        </div>
                        <br/>
                        <p>
                            1250 Capital of Texas Highway South<br/>
                            Building 3, Suite 400<br/>
                            Austin, TX 78746<br/>
                            <br/>
                        </p>
                    </div>
                    
                    <div className="contact-map">
                        <iframe title="map" frameBorder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?q=1250%20S%20Capital%20of%20Texas%20Hwy%20West%20Lake%20Hills%2C%20TX%2078746&key=AIzaSyB0S-kryqdFRKYr_8l-ehC380rU4gHVq0s"></iframe>    
                    </div>   
                </div>

                <h2>Caldwell</h2>
                <div className="contact-all">
                    
                    <div className="contact-text">
                        
                    <div className="city">
                            <img src={caldwellOffice} alt="Austin"/>
                        </div>
                        <div className="contact-info">
                            <img src={phone} alt=""/>
                            <a href="tel:979-567-1710">(979) 567-1710</a>
                        </div>
                        <div className="contact-info">
                            <img src={phone} alt=""/>
                            <a href="tel:979-567-1766">(979) 567-1766 (fax)</a>
                        </div>
                        <div className="contact-info">
                            <img src={mail} alt=""/>
                            <a href="mailto:info@capertonmoore.com">info@capertonmoore.com</a>
                        </div>
                        <br/>
                        <p>
                            106 S. Echols<br/>
                            Caldwell, Texas 77836<br/>
                            <br/>
                        </p>
                    </div>
                    
                    <div className="contact-map">
                        <iframe title="map" frameBorder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCfRx-No5RIYRlkOE669JMDg&key=AIzaSyB0S-kryqdFRKYr_8l-ehC380rU4gHVq0s"></iframe>
                    </div>   
                </div>

                <h2>Lakeway</h2>
                <div className="contact-all">
                    
                    <div className="contact-text">
                        
                        <div className="city">
                            <img src={lakewayOffice} alt="Austin"/>
                        </div>
                        <p>By appointment only</p><br/>

                        <div className="contact-info">
                            <img src={phone} alt=""/>
                            <a href="tel:512-329-2076">(512) 329-2076</a>
                        </div>
                        <div className="contact-info">
                            <img src={mail} alt=""/>
                            <a href="mailto:info@capertonmoore.com">info@capertonmoore.com</a>
                        </div>
                        <br/>
                        <p>
                            Hill Country Galleria Offices<br/>
                            12600 Hill Country Boulevard<br/>
                            Suite R-275<br/>
                            Bee Cave, Texas 78738<br/>
                            <br/>
                        </p>
                    </div>
                    
                    <div className="contact-map">
                        <iframe title="map" frameBorder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJqR1nJ8A3W4YR5nEf9wqqwvA&key=AIzaSyB0S-kryqdFRKYr_8l-ehC380rU4gHVq0s"></iframe>  
                    </div>   
                </div>

                <h2>Houston Area</h2>
                <div className="contact-all">
                    
                    <div className="contact-text">
                        
                    <div className="city">
                            <img src={houstonOffices} alt="Houston"/>
                        </div>

                        <p>By appointment only</p><br/>
                        
                        <div className="contact-info">
                            <img src={phone} alt=""/>
                            <a href="tel:281-536-9791">(281) 536-9791</a>
                        </div>
                        <div className="contact-info">
                            <img src={mail} alt=""/>
                            <a href="mailto:info@capertonmoore.com">info@capertonmoore.com</a>
                        </div>
                        <br/>
                    </div>
                    
                    <div className="contact-map">
                        <p>We have several remote office locations in the Houston area, including Greenway, Westside, Pearland, Sugar Land Town Center, and North/Woodlands. Call (281) 536-9791 to schedule an appointment at a convenient location.<br/>
                        </p>
                        <br/>
                    </div>   
                </div>
            </section>

{/* DISCLAIMER */}

            <section id="disclaimer">
                <div className="section-header" id="disclaimer">
                    <h1>Disclaimer</h1>
                </div>
                <div className="section-details">
                    <p>Materials on this website are provided for informational purposes only, do not constitute legal advice, do not necessarily reflect the opinions of Caperton & Moore PLLC, or any of its lawyers or clients, and are not guaranteed to be complete, correct, or up-to-date. This website is not intended to create a lawyer-client relationship between you and Caperton & Moore PLLC or any of its lawyers. You should not act or rely on information in this website without seeking the advice of a lawyer. We would be pleased to communicate with you concerning legal matters, but we cannot do so until we first know that doing so would not create a conflict of interest. If you are interested in having Caperton & Moore PLLC represent you in a legal matter, the best way to initiate possible representation is to contact the firm at (512) 329-2076. We will arrange to have you go through our conflict of interest procedures and following that put you directly in touch with the lawyer best suited to handle the matter. You may also communicate with Caperton & Moore PLLC by e-mail through the link located in the “Contact” section of this website. Please do not send us any confidential information until after you have received from us a written statement that we represent you in that matter (an “engagement letter”). If you communicate with us through this website, by e-mail or otherwise concerning a legal matter for which we do not already represent you, your communication may not be treated as privileged or confidential. While lawyer-client communications that would be privileged if communicated in traditional fashion should not lose their privileged character when communicated without encryption via the internet, encryption protocols supported by Caperton & Moore PLLC are available upon request to clients that desire them for especially sensitive communications.</p>

                    <p>This website contains links to other resources on the Internet. Those links are provided as citations and aids to help you identify and locate other Internet resources that may be of interest, and are not intended to state or imply that Caperton & Moore PLLC sponsors, endorses, is affiliated or associated with, or is legally authorized to use any trade name, registered trademark, logo, legal or official seal, or copyrighted symbol that may be reflected therein.</p>
                    
                    <p>To the extent the State Bar Rules of a jurisdiction require us to designate a principal office and/or a single lawyer responsible for this website, Caperton & Moore PLLC designates its office in Austin, TX as its principal office and designates Dirk R. Moore as the lawyer responsible for this website.</p>

                </div>
            </section>

{/* FOOTER */}

            <div className="footer">
                <div className="disclaimer-copyright">
                    <p>Copyright © 2019 Caperton & Moore PLLC</p>
                </div>
                <div className="contact">
                    Phone: (512) 329-2076<br/>
                    Email: info@capertonmoore.com<br/>
                    <br/>
                    1250 Capital of Texas Highway South<br/>
                    Austin, TX 78746
                </div>
            </div>

        </div>

    )
}

export default Homepage